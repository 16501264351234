
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { Appraisal } from '@/entities/purchase'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixPrice, stringifySafe } from '@/utils/general'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Deal, Lead } from '@/entities/crm'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'

@Component({
  components: { StockPhotos, GRadioButton, GFiles, GAlert, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class SupervisorAppraisalForm extends AppraisalView {
  appraisal: Appraisal = plainToInstance(Appraisal, {})
  lead: Lead = plainToInstance(Lead, {})
  title = ''
  attributes = []
  subtitle = 'Gestiona tu tasación, apelando o agregando información relevante para definir el valor comercial del vehículo'
  showDetail = false
  errorEstimatedPrice = ''
  idProcess = null
  formData = {
    offer1: null,
    offer2: null,
    appraisalInit: null,
    comment: null,
    companies: null,
    estimatedPrice: null,
    cav: [],
    isValidCav: null,
    cavComment: '',
    link: '',
  }

  radioButtonMessage = {
    isValidCav: '',
  }

  metadata = {}
  metadataCollection = {}
  fields = {
    cav: {
      properties: {
        label: '',
        accept: '',
        multiple: false,
        fileTypeId: '',
        name: '',
        required: false,
      },
    },
    offer1: {
      properties: {
        label: '',
        link: '',
      },
    },
    offer2: {
      properties: {
        label: '',
        link: '',
      },
    },
    appraisalInit: {
      properties: {
        label: 'Tasación',
      },
    },
    comment: {
      properties: {
        label: 'Comentario',
      },
    },
    estimatedPrice: {
      properties: {
        label: 'Precio estimado de publicación',
      },
    },
    button1: '',
    button2: '',
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()
    const { uid, id, model, metadataCollection, title } = this
    const appraisalId = id || uid
    if ((!isNaN(uid) && (model === 'Appraisal' || model === '')) || !isNaN(id)) {
      await this.getAppraisalInfo(appraisalId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(!isNaN(appraisalId)))
    }
    this.loadingForm = false
  }

  async getAppraisalInfo (id) {
    const appraisal = await this.fetchData({
      query: { name: 'fetch', model: 'Appraisal', params: { id } },
      force: true,
    })
    await this.findAlternativesAttributes(appraisal.deal.auto)
    const { metadataCollection } = this
    const { fields } = metadataCollection as Form

    const offer1 = appraisal.externalOffers.filter(offer => offer.company.id === fields.clicar_offer.init.value.company.id)
    this.formData.offer1 = offer1[0]?.amount
    const offer2 = appraisal.externalOffers.filter(offer => offer.company.id === fields.olx_offer.init.value.company.id)
    this.formData.offer2 = offer2[0]?.amount
    this.formData.estimatedPrice = appraisal?.expectedPublicationAmount
    this.formData.link = appraisal?.link
    if ((appraisal?.status?.isAppraised || appraisal?.status?.isCavNotMatch) && Boolean(appraisal?.appraisal)) {
      const { appraisal: appraised } = appraisal
      this.formData.appraisalInit = appraised.amount
      this.formData.comment = appraised.comment
    }

    this.appraisal = appraisal
    this.appraisal.deal.auto.dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: { id_process_record: { _eq: appraisal?.id }, process: { table_name: { _eq: 'appraisal' } } },
      force: true,
    })
    await this.setAppraisalInfo(appraisal)
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      this.attributes = generation?.attributes
      return
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      this.attributes = attributes
      return
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        this.attributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    this.attributes = generation?.attributes || []
  }

  async setAppraisalInfo (appraisal) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    const idProcess = process[0].id

    this.idProcess = idProcess
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: appraisal.id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'cav' } } } }] },
      force: true,
    })

    if (cav.length) {
      this.appraisal.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
        id: appraisal.id,
      }
      this.formData.cav = cav?.length ? cav : []
      this.formData.isValidCav = cav[0].valid
    }

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'appraisal' } } },
    })

    this.setProperties(fileInfo, 'cav', 'cav')
    await this.setDetails()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Appraisal', 'supervisor_appraisal')
    const { fields, form } = metadata as Form
    this.metadataCollection = metadata
    this.title = form.title
    this.fields.offer1.properties.label = fields.clicar_offer.init.value.company.alias
    this.fields.offer2.properties.label = fields.olx_offer.init.value.company.alias

    this.formData.companies = {
      offer1: fields.clicar_offer.init.value.company,
      offer2: fields.olx_offer.init.value.company,
    }
    const { webSite: webSite1 } = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: fields.clicar_offer.init.value.company.id } },
    })
    const { webSite: webSite2 } = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: fields.olx_offer.init.value.company.id } },
    })
    this.fields.offer1.properties.link = webSite1
    this.fields.offer2.properties.link = webSite2

    this.fields.button1 = fields.button1.properties.label
    this.fields.button2 = fields.button2.properties.label
  }

  validateFields () {
    const { formData: { cav } } = this

    if (!cav.length) {
      return false
    }

    const keys = ['isCav']

    let flag = false
    keys.forEach(key => {
      if (this.formData[key] === null) {
        this.radioButtonMessage[key] = 'Campo requerido'
        flag = true
      }
    })

    return flag
  }

  async send () {
    const errorMessage = this.errorEstimatedPrice?.length

    if (!this.$refs.form.validate() || this.validateFields() || errorMessage) {
      return
    }
    this.loadingForm = true
    const { appraisal: { id, externalOffers, responses, expectedPublicationAmount, appraiser }, formData } = this

    if (id) {
      await this.insertUpdateExternalOffer(externalOffers, formData, id)

      if (formData.appraisalInit) {
        await this.insertUpdateResponse(responses, formData, id)
        await this.pushData({
          model: 'Appraisal',
          fields: { id, id_process_status: this.statusAppraisal.appraised[0].id, link: formData.link },
        })
      }

      if (formData?.estimatedPrice !== expectedPublicationAmount) {
        await this.pushData({
          model: 'Appraisal',
          fields: { id, expected_publication_amount: formData.estimatedPrice, link: formData.link },
        })
      }
    }

    if (formData.cav.length && formData.isValidCav && id) {
      await this.insertCavValidation(id, formData.cav, formData.isValidCav, formData.cavComment)
    } else if (formData.cav.length && id) {
      await this.closeAppraisal()
    }

    if (!appraiser) {
      await this.pushData({ model: 'Appraisal', fields: { id, id_appraiser: this.idEmployee } })
    }

    await this.close()
  }

  async closeAppraisal () {
    const { appraisal } = this
    const statusClosed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'appraisal' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const reason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'not_qualify' } } }, { status: { process: { table_name: { _eq: 'appraisal' } } } }] },
    })

    await this.pushData({
      model: 'Appraisal',
      fields: {
        id: appraisal.id,
        id_process_status: statusClosed[0].id,
        id_closing_reason: reason[0].id,
      },
    })

    const notMatchStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'auto' } } }, { status: { name: { _eq: 'deprecated' } } }] },
    })

    await this.pushData({
      model: 'Auto',
      fields: {
        id: appraisal.auto.id,
        id_process_status: notMatchStatus?.[0]?.id,
      },
    })

    const dealLost = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'deal' } } }, { status: { name: { _eq: 'lost' } } }] },
    })

    const dealFilter = {
      _and: [
        { type: { name: { _eq: 'not_match' } } },
        { status: { process: { table_name: { _eq: 'deal' } } } },
      ],
    }

    const dealReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: dealFilter,
    })

    await this.updateDealProcessStatusAndClosingReason({
      id: appraisal.deal.id,
      idProcessStatus: dealLost?.[0]?.id,
      idClosingReason: dealReason?.[0]?.id,
    })
  }

  async insertUpdateResponse (responses, formData, id) {
    const response = responses[0]

    if (response?.amount !== formData?.appraisalInit) {
      if (response?.isAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: response.id,
            id_appraisal: id,
            amount: formData.appraisalInit,
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.appraisalInit,
            type: 'appraised',
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      }
    }
  }

  async insertUpdateExternalOffer (externalOffers, formData, id) {
    const offer1 = externalOffers.filter(offer => offer.company.id === formData.companies.offer1.id)[0]
    if (offer1?.amount !== formData.offer1) {
      if (offer1?.id) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id: offer1.id, amount: formData.offer1 },
        })
      } else {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id_appraisal: id, amount: formData.offer1, id_person: formData.companies.offer1.id },
        })
      }
    }
    const offer2 = externalOffers.filter(offer => offer.company.id === formData.companies.offer2.id)[0]
    if (offer2?.amount !== formData.offer2) {
      if (offer2?.id) {
        await this.pushData({ model: 'AppraisalExternalOffer', fields: { id: offer2.id, amount: formData.offer2 } })
      } else {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id_appraisal: id, amount: formData.offer2, id_person: formData.companies.offer2.id },
        })
      }
    }
  }

  get disabledChanges () {
    const { appraisal } = this

    return Boolean(appraisal?.closingReason)
  }

  get appraisalDisabled () {
    const { appraisal } = this

    const isAgreedAmount = Boolean(appraisal?.agreedAmount)

    if (isAgreedAmount) return isAgreedAmount

    return (!appraisal?.status?.isPending && !appraisal?.status?.isNotOffer && !appraisal?.status?.isAppraised) && Boolean(appraisal?.id) && !appraisal?.status?.isAppealed
  }

  get appraisalClosingReason () {
    const { appraisal } = this
    return Boolean(appraisal?.closingReason?.id)
  }

  get stock () {
    const { appraisal } = this

    return {
      auto: appraisal?.deal?.auto,
    }
  }

  get cavNotMatch () {
    const { appraisal } = this
    return appraisal?.status?.isCavNotMatch
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get auto () {
    const { appraisal } = this

    return appraisal?.deal?.auto
  }

  get fuel () {
    const { attributes } = this

    return attributes.find(att => att.component?.slug === 'fuel').val
  }

  get transmission () {
    const { attributes } = this

    return attributes.find(att => att.component?.slug === 'transmission').val
  }

  get mileage () {
    const { appraisal } = this
    const att = appraisal?.deal?.autoAttributes?.find(att => att.slug === 'mileage')
    return fixPrice(att?.val, false)
  }

  get owners () {
    const { appraisal } = this

    const att = appraisal?.deal?.autoAttributes?.find(att => att.slug === 'owners_number')

    return att?.val
  }

  get traction () {
    const { attributes } = this

    return attributes?.find(att => att?.component?.slug === 'traction')?.val
  }

  async setDetails () {
    const { metadataCollection, appraisal } = this

    if (appraisal?.appraiser?.id) {
      const appraiser = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { employee: { id: { _eq: appraisal.appraiser.id } } },
      })
      appraisal.appraiser = appraiser[0]
    }
    const customLead = await this.getLeadById(appraisal.deal.lead.id)

    appraisal.deal.lead.deals = customLead.deals?.map(deal => plainToInstance(Deal, deal))
    const sale = customLead.deals?.map(deal => plainToInstance(Deal, deal))?.find(deal => deal.isSale)
    if (sale) {
      const auto = await this.fetchData({
        query: { name: 'find', model: 'Auto' },
        filter: { id: { _eq: sale?.auto?.id } },
      })

      const stock = await this.fetchData({
        query: { name: 'find', model: 'Stock' },
        filter: { id: { _eq: sale?.stock?.id } },
      })

      appraisal.deal.lead.sale.auto = auto?.[0]
      appraisal.deal.lead.sale.stock = stock?.[0]
    }

    this.metadata = {
      data: appraisal,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(appraisal.id)
  }

  async goToButton () {
    const { stock } = this

    await this.openPortal('https://autos.mercadolibre.cl/marca/modelo', stock, false)
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    if (val.estimatedPrice && val.appraisalInit && Number(val.estimatedPrice) < Number(val.appraisalInit)) {
      this.errorEstimatedPrice = 'La tasación no puede ser mayor al precio estimado de publicación'
    } else {
      this.errorEstimatedPrice = ''
    }
  }

  async openAppraisalLink () {
    const { auto, appraisal } = this

    if (appraisal?.link) {
      this.openLink(appraisal.link)
      return
    }

    await this.buildLinkChileAutos(auto)
  }
  }
